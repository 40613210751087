import { template } from '@shein/common-function'
import { TRANSPORT_TYPE, NEW_TRANSPORT_TIME_TYPE, NEW_OUT_TRANSPORT_TIME_TYPE, DATE_TYPE } from './constant'
import { formatTime, formatDoubleTime, splitTransportTime, formatDoubleTimeNew } from './utils'


// 替换{QSname}占位
export const replaceQuickShip = (quickShipDesc = '', quickShipWord = '') => {
  if (typeof quickShipDesc != 'string') return ''
  return quickShipDesc.replace(/{QSname}/g, quickShipWord)
}

// 替换qs时效文案中的%s时间占位符
export const replaceField = (quickShipDesc = '', timeStr = '') => {
  if (typeof quickShipDesc != 'string') return ''
  return quickShipDesc.replaceAll('%s', timeStr)
}

// 判断是否有配置描述文案
export const hasTimeDesc = (item = {}) => {
  return Boolean(
    (item.transport_time_type == NEW_TRANSPORT_TIME_TYPE.DAY_RANGE && item.origin_shipping_desc) ||
    (item.transport_time_type == NEW_TRANSPORT_TIME_TYPE.DATE_RANGE && item.origin_delivery_desc)
  )
}

// 获取qs时效对象
export const getQsInfo = (transportTimeList = []) => {
  return transportTimeList.find(item => {
    return item.type == TRANSPORT_TYPE.QUICK_SHIP_TIME
  })
}

// 获取nm时效对象
export const getNormalInfo = (transportTimeList = []) => {
  return transportTimeList.find(item => {
    return item.type == TRANSPORT_TYPE.NORMAL_TIME
  })
}

// 获取店铺时效信息
export const getShopInfo = (transportTimeList = []) => {
  return transportTimeList.find(item => {
    return item.type == TRANSPORT_TYPE.SHOP_TIME && hasTimeDesc(item)
  })
}

// 获取qs时效配置文案
export const getQuickShipDesc = (qsInfo = {}, localData) => {
  let quickShipDesc = ''
  if (qsInfo?.transport_time_type == NEW_TRANSPORT_TIME_TYPE.DAY_RANGE) {
    quickShipDesc = qsInfo.origin_shipping_desc || ''
  } else if (qsInfo?.transport_time_type == NEW_TRANSPORT_TIME_TYPE.DATE_RANGE) {
    quickShipDesc = qsInfo.origin_delivery_desc || ''
  }
  // 如果有时间占位符，格式化时间进行替换
  if (quickShipDesc.includes('%s')) {
    const formatDate = formatTime(qsInfo?.transport_time, localData) || ''
    return replaceField(quickShipDesc, formatDate)
  }
  return quickShipDesc
}

// 获取qs商品类型 0-没有qs商品 1-部分qs商品 2-全部qs商品
export const getQuickShipType = (goodsList = []) => {
  const newGoodsList = (goodsList || []).flat(Infinity)
  // 需要过滤掉的商品状态
  const disabledGoodsStatus = [75, 76, 77, 91, 5, 7, 20, 126, 127, 220]
  let fullCondGoodsList = newGoodsList.filter(
    i => !disabledGoodsStatus.includes(+i.status) && !i.is_prime_goods
  )
  let quickShipGoods = fullCondGoodsList.filter(item => item.quick_ship == 1)
  let someQuickShipGoods = fullCondGoodsList.filter(item => item.quick_ship == 2)
  if (quickShipGoods.length || someQuickShipGoods.length) {
    return fullCondGoodsList.length == quickShipGoods.length ? 2 : 1
  } else {
    return 0
  }
}

// 获取N日达配置值
export const getQuickshipWord = (qsInfo = {}, localData) => {
  let orderQuickshipWord = ''
  if (qsInfo?.transport_time_type == NEW_TRANSPORT_TIME_TYPE.DAY_RANGE) {
    orderQuickshipWord = splitTransportTime(qsInfo.transport_time, localData)
  } else if (qsInfo?.transport_time_type == NEW_TRANSPORT_TIME_TYPE.DATE_RANGE) {
    orderQuickshipWord = splitTransportTime(qsInfo.sub_transport_time, localData)
  }
  return orderQuickshipWord
}

// 获取N跨境日达配置值
export const getOutQuickshipWord = (qsInfo = {}, localData) => {
  let orderQuickshipWord = ''
  if (qsInfo?.transport_time_type == NEW_OUT_TRANSPORT_TIME_TYPE.DAY_RANGE) {
    orderQuickshipWord = splitTransportTime(qsInfo.transport_time, localData)
  } else if (qsInfo?.transport_time_type == NEW_OUT_TRANSPORT_TIME_TYPE.DATE_RANGE) {
    orderQuickshipWord = splitTransportTime(qsInfo.sub_transport_time, localData)
  }
  return orderQuickshipWord
}

// 根据配置格式化quick_ship_desc文案
export const formatQuickShipDesc = (params = {}) => {
  const { 
    language = {}, // 多语言对象
    quickShipAbt = {}, // N日达abt配置
    localData = {}, // responseLocals
    goodsList = [], //商品列表
    timeList = [], // 时效列表
    isBrazil = false, // 国家是否是巴西
    isUnPaidOrder = false, // 是否未支付（true-未支付 false-已支付）
    showDoubleShippingTime = false,  // 是否开启双时效abt
    localSwitchinternAbt = {}, // 日本站点N日达abt配置
    isJapan = false, // 国家是否是日本
  } = params
  // 优先取店铺时效
  let qsInfo = getShopInfo(timeList)
  if (!qsInfo) {
    // 获取qs时效
    qsInfo = getQsInfo(timeList)
  }
  if (!qsInfo) return ''
  const { 
    transport_time: transportTime,
    origin_delivery_desc: originDeliveryDesc = '',
    origin_shipping_desc: originShippingDesc = '',
    sub_transport_time: subTransportTime = '',
    transport_time_type: timeType,
    supple_desc: suppleDesc = '',
  } = qsInfo
  let quickShipDesc = getQuickShipDesc(qsInfo, localData)
  const qsType = getQuickShipType(goodsList)
  // 全部qs商品-添加补充文案
  if (suppleDesc && qsType == 2) {
    quickShipDesc = `${quickShipDesc} ${suppleDesc}`
  }
  //满足双时效展示条件
  if (
    showDoubleShippingTime &&
    !isUnPaidOrder &&
    originDeliveryDesc &&
    originShippingDesc &&
    subTransportTime
  ) {
    // qs满足双时效展示条件
    quickShipDesc = formatDoubleTime({
      originDeliveryDesc,
      originShippingDesc,
      subTransportTime,
      transportTime,
      transportTimeType: timeType,
      useNewEnum: true,
      localData
    }) 
  } 
  // 没有占位符直接返回
  if (!quickShipDesc.includes('{QSname}')) {
    return quickShipDesc
  }
  // 巴西N日达单独处理
  if (isBrazil) {
    const orderQuickshipWord = getQuickshipWord(qsInfo, localData)
    if (qsInfo?.days_delivery_flag == 1 && orderQuickshipWord) {
      return replaceQuickShip(quickShipDesc, 
        template(orderQuickshipWord, language.SHEIN_KEY_PWA_30600)
      )
    } 
    return ''
  }
  // 日本N日达单独处理
  if(isJapan && localSwitchinternAbt?.param?.jp_N == 'local') {
    const orderQuickshipWord = getQuickshipWord(qsInfo, localData)
    if (
      qsInfo?.days_delivery_flag == 1 && 
      orderQuickshipWord
    ) {
      return replaceQuickShip(quickShipDesc,
        template(orderQuickshipWord, language.SHEIN_KEY_PWA_30600)
      )
    }
  }

  // 获取N日达配置并进行替换
  if (quickShipAbt.allSceneQuickshipWordStatus == 'on') {
    const orderQuickshipWord = getQuickshipWord(qsInfo, localData)
    if (orderQuickshipWord) {
      return replaceQuickShip(
        quickShipDesc,
        template(orderQuickshipWord, language.SHEIN_KEY_PWA_30600)
      )
    }
  }
  return replaceQuickShip(quickShipDesc, language.SHEIN_KEY_PWA_22276)
}

// 根据配置格式化quick_ship标签文案
export const formatQuickShipLabel = (params = {}) => {
  const { language = {}, quickShipAbt = {}, mallInfo = {}, localData = {} } = params
  if (quickShipAbt.allSceneQuickshipWordStatus == 'on') {
    const transportTimeList = mallInfo?.transport_time_list || []
    // 优先取店铺时效
    let qsInfo = getShopInfo(transportTimeList)
    if (!qsInfo) {
      // 获取qs时效
      qsInfo = getQsInfo(transportTimeList)
    }
    const orderQuickshipWord = getQuickshipWord(qsInfo, localData)
    if (orderQuickshipWord) {
      return template(orderQuickshipWord, language.SHEIN_KEY_PWA_30600)
    }
  }
  return language.SHEIN_KEY_PWA_22276
}

// 格式化巴西N日达
export const formatBrQuickShipLabel = (params = {}) => {
  const { 
    language = {}, 
    mallInfo = {},
    localData = {}
  } = params
  const transportTimeList = mallInfo?.transport_time_list || []
  // 优先取店铺时效
  let qsInfo = getShopInfo(transportTimeList)
  if (!qsInfo) {
    // 获取qs时效
    qsInfo = getQsInfo(transportTimeList)
  }
  const orderQuickshipWord = getQuickshipWord(qsInfo, localData)
  if (orderQuickshipWord) {
    return template(orderQuickshipWord, language.SHEIN_KEY_PWA_30600)
  }
  return ''
}

// 是否显示巴西N日达
export const showBrNDayDelivery = (params = {}) => {
  const { 
    mallInfo = {},
    goodsList = [],
  } = params
  // 存在商品层级quick_ship标识=1
  if (goodsList.some(i => i.quick_ship == 1)) {
    const transportTimeList = mallInfo?.transport_time_list || mallInfo?.shipped_transport_time_list || []
    return transportTimeList.some(i => {
      // 「是否展示N日达」的值=“是”
      const showStatus = i.days_delivery_flag == 1 && [1, 3].includes(+i.type)
      let hasData = false // QS时效有值
      if (i.transport_time_type == 1 && i.transport_time) {
        hasData = true
      } else if (i.transport_time_type == 2 && i.sub_transport_time) {
        hasData = true
      }
      return showStatus && hasData
    })
  }
  return false
}

// 格式化日本N日达
export const formatJpQuickShipLabel = (params = {}) => {
  const { language = {}, mallInfo = {}, localData = {}, localSwitchinternAbt } = params
  const transportTimeList = mallInfo?.transport_time_list || []
  let qsInfo, orderQuickshipWord
  if(localSwitchinternAbt?.param?.jp_N == 'local') {
    qsInfo = getQsInfo(transportTimeList)
    orderQuickshipWord = getQuickshipWord(qsInfo, localData)
  } else if (localSwitchinternAbt?.param?.jp_N == 'transnation') {
    qsInfo = getNormalInfo(transportTimeList)
    orderQuickshipWord = getOutQuickshipWord(qsInfo, localData)
  }
  
  if (orderQuickshipWord) {
    return template(orderQuickshipWord, language.SHEIN_KEY_PWA_30600)
  } else {
    return ''
  }
}

// 是否显示tab上日本N日达
export const showPackageJpNDayDelivery = (params = {}) => {
  const { 
    mallInfo = {},
    goodsList = [],
    localSwitchinternAbt = {},
  } = params
  
  if(localSwitchinternAbt?.param?.jp_N == 'local') {
    if (goodsList.every(i => [1].includes(+i.quick_ship))) {
      const transportTimeList = mallInfo?.transport_time_list || []
      let  res =  transportTimeList.some(i => {
        // 「是否展示N日达」的值=“是”
        const showStatus = i.days_delivery_flag == 1 && [1].includes(+i.type)
        let hasData = false // QS时效有值
        if (i.transport_time_type == 1 && i.transport_time) {
          hasData = true
        } else if (i.transport_time_type == 2 && i.sub_transport_time) {
          hasData = true
        }
        return showStatus && hasData
      })
      return res
    }
    return false
  } else if (localSwitchinternAbt?.param?.jp_N == 'transnation') {
    if (goodsList.some(i => ![1, 2].includes(+i.quick_ship) && ![1, 2].includes(+i.large_sea_and_land))) {
      const transportTimeList = mallInfo?.transport_time_list || []
      return transportTimeList.some(i => {
        // 「是否展示N日达」的值=“是”
        const showStatus = i.days_delivery_flag == 1 && [0].includes(+i.type)
        let hasData = false // QS时效有值
        if (i.transport_time_type == 0 && i.transport_time) {
          hasData = true
        } else if (i.transport_time_type == 1 && i.sub_transport_time) {
          hasData = true
        }
        return showStatus && hasData
      })
    }
  }
  
  return false
}

// 是否显示日本N日达
export const showJpNDayDelivery = (params = {}) => {
  const { 
    mallInfo = {},
    goodsList = [],
    localSwitchinternAbt = {},
  } = params
  if(localSwitchinternAbt?.param?.jp_N == 'local') {
    if (goodsList.some(i => [1, 2].includes(+i.quick_ship))) {
      const transportTimeList = mallInfo?.transport_time_list || []
      let  res =  transportTimeList.some(i => {
        // 「是否展示N日达」的值=“是”
        const showStatus = i.days_delivery_flag == 1 && [1].includes(+i.type)
        let hasData = false // QS时效有值
        if (i.transport_time_type == 1 && i.transport_time) {
          hasData = true
        } else if (i.transport_time_type == 2 && i.sub_transport_time) {
          hasData = true
        }
        return showStatus && hasData
      })
      return res
    }
    return false
  } else if (localSwitchinternAbt?.param?.jp_N == 'transnation') {
    if (goodsList.some(i => ![1, 2].includes(+i.quick_ship) && ![1, 2].includes(+i.large_sea_and_land))) {
      const transportTimeList = mallInfo?.transport_time_list || []
      return transportTimeList.some(i => {
        // 「是否展示N日达」的值=“是”
        const showStatus = i.days_delivery_flag == 1 && [0].includes(+i.type)
        let hasData = false // QS时效有值
        if (i.transport_time_type == 0 && i.transport_time) {
          hasData = true
        } else if (i.transport_time_type == 1 && i.sub_transport_time) {
          hasData = true
        }
        return showStatus && hasData
      })
    }
  }
  return false
}

export const formatQuickShipDescNew = (params = {}) => {
  const { 
    language = {},  // 多语言对象
    localData = {}, // responseLocals
    timeList = [], // 时效列表
    showDoubleShippingTime = false,  // 是否开启双时效abt
  } = params

  // 优先取店铺时效
  let qsInfo = getShopInfo(timeList)
  if (!qsInfo) {
    // 获取qs时效
    qsInfo = getQsInfo(timeList)
  }
  if (!qsInfo) return ''
  const { 
    transport_time: transportTime,
    sub_transport_time: subTransportTime = '',
    transport_time_type: timeType,
    transport_date_type: dataType,
  } = qsInfo
  let doubleTransportText, text
  //满足双时效展示条件
  if (
    showDoubleShippingTime &&
    subTransportTime
  ) {
    // qs满足双时效展示条件
    doubleTransportText = formatDoubleTimeNew({
      subTransportTime,
      transportTime,
      language,
      dataType, 
      transportTimeType: timeType,
      useNewEnum: true,
      localData
    }) 
  } 

  if (timeType == NEW_TRANSPORT_TIME_TYPE.DATE_RANGE) {
    const formatDate = formatTime(transportTime, localData)
    text = formatDate
  } else if (timeType == NEW_TRANSPORT_TIME_TYPE.DAY_RANGE) {
    if (dataType == DATE_TYPE.NATURAL_DAY) {
      text = template(transportTime, language.SHEIN_KEY_PWA_28881)
    } else if (dataType == DATE_TYPE.WORKING_DAY) {
      text = template(transportTime, language.SHEIN_KEY_PWA_24764)
    }  else {
      text = template(transportTime, language.SHEIN_KEY_PWA_24764)
    }
  }

  return {
    doubleTransportText,
    text
  }
}
